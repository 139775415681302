import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DetailsPage from '../../../shared/components/DetailsPage/DetailsPage';
import { UsersTable } from './Tabs/UsersTable';
import { readPortfolio } from '../../../services/portfolio';
import BrokerageNotes from './Tabs/BrokerageNotes';
import OrgsTable from './Tabs/OrgsTable';
import OrgUsersTable from './Tabs/OrgUsersTable';

const PortfolioDetails = () => {
  const { t: translate } = useTranslation();
  const { PortfolioId } = useParams();

  const history = useHistory();

  const [portfolioData, setPortfolioData] = useState(history?.location?.state?.portfolioData || {});

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [usersCount, setUsersCount] = useState(null);
  const [orgsCount, setOrgsCount] = useState(null);
  const [orgUsersCount, setOrgUsersCount] = useState(null);

  useEffect( () => {
    const getDetails = async () => {
      try {
        setLoading(true);
        const response = await readPortfolio(PortfolioId);
        setPortfolioData(response.data);
      } catch (err) {
        setMessage(translate('it was not possible to search portfolio details'));
      }
      setLoading(false);
    };

    if (!Object.keys(portfolioData).length) {
      getDetails();
    }
  }, [PortfolioId, portfolioData, translate]);


  const mapAutoRnc = (data) => {
    const rnc = data?.autoRnC ? 'automatic' : 'manual';
    return translate(rnc);
  };

  const header = {
    title: portfolioData?.name,
    fields: [
      {
        name: 'portfolioId',
        data: portfolioData?.id
      },
      {
        name: 'contributions and redemptions',
        data: mapAutoRnc(portfolioData)
      }
    ]
  };

  const tabs = [
    {
      name: `${translate('users')}` + (usersCount !== null ? ` (${usersCount})` : ''),
      content: {
        component: UsersTable,
        props: {
          setUsersCount
        }
      }
    },
    {
      name: `${translate('organizations')}` + (orgsCount !== null ? ` (${orgsCount})` : ''),
      content: {
        component: OrgsTable,
        props: {
          setOrgsCount
        }
      }
    },
    {
      name: `${translate('organization users')}` + (orgUsersCount !== null ? ` (${orgUsersCount})` : ''),
      content: {
        component: OrgUsersTable,
        props: {
          setOrgUsersCount
        }
      }
    },
    {
      name: translate('brokerage notes'),
      content: {
        component: BrokerageNotes
      }
    }

  ];

  return (
    <DetailsPage
      tabs={tabs}
      message={message}
      loading={loading}
      header={header}
      data={portfolioData}
      setMessage={setMessage}
    />
  );
};

export default PortfolioDetails;
