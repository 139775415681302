import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import UserDetails from './Details/UserDetails';
import { useTranslation } from 'react-i18next';
import CreateUser from './Create/CreateUser';
import Relations from './Relations/Relations';
import UserSearch from './UserSearch/UserSearch';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const User = (props) => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;
  const classes = useStyles();

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  };

  const isPageActive = (page) => pathName.search(page) !== -1;

  return (
    <>
      <Grid container justifyContent="center" alignItems="baseline">
        <Grid item>
          <Button color="primary" className={isPageActive('search') ? classes.active : ''} onClick={() => handleChangePage('search')}>
            {translate('search users')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('create') ? classes.active : ''} onClick={() => handleChangePage('create')}>
            {translate('create user')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('relations') ? classes.active : ''} onClick={() => handleChangePage('relations')}>
            {translate('mirror associations')}
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Switch>
          <Route path={previousRoute + '/search'} component={UserSearch}/>
          <Route path={previousRoute + '/details/:FundID'} component={UserDetails} />
          <Route path={previousRoute + '/create'} component={CreateUser} />
          <Route path={previousRoute + '/relations'} component={Relations} />
          <Redirect to={previousRoute + '/search'} />
        </Switch>
      </Grid>
    </>
  );
};

export default User;
