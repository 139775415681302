import React, { useState } from 'react';
import { CreateEntityForm } from '../../../shared/components';
import { userTypes } from '../../../shared/userTypes';
import { createUser } from '../../../services/user';

const CreateUser = () => {
  // eslint-disable-next-line
  const validEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const defaultFields = [
    { name: 'name', type: 'text' },
    { name: 'email', type: 'email' },
    { name: 'password', type: 'password' }
  ];

  const [fields, setFields] = useState(defaultFields);

  const radioGroup = {
    name: 'type',
    options: [
      userTypes.advisor,
      userTypes.individual
    ],
    onChange: ({ event, formData, setFormData }) => {
      const organizationInput = { name: 'organization', type: 'text' };
      const { value } = event.target;

      if (value === userTypes.advisor) {
        setFormData((prevData) => ({ ...prevData, [organizationInput.name]: '' }));
        setFields([...defaultFields, organizationInput]);
      } else if (value === userTypes.individual) {
        const updateData = { ...formData };
        delete updateData[organizationInput.name];

        setFormData(updateData);
        setFields(defaultFields);
      }
    }
  };


  const validation = (formData, field) => {
    const value = formData[field].trim();

    if (value === '') {
      return 'required field';
    }

    if (field === 'password') {
      if (value.length < 6) {
        return 'password must have at least 6 digits';
      }
    } else if (field === 'email') {
      if (!validEmail.test(value)) {
        return 'invalid email';
      }
    } else if (field === 'organization') {
      const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      if ( value !== '' && !uuidRegex.test(value)) {
        return 'field must be a organization id';
      }
    }

    return '';
  };

  const errorMessage = (status)=> {
    switch (status) {
    case 400:
      return 'invalid parameters provided';
    case 409:
      return 'email already exists';
    case 404:
      return 'organization does not exist';
    default:
      return '';
    }
  };

  const action = async (formData) => {
    let error;
    let message;

    try {
      await createUser(formData);
      error = false;
      message = 'user successfully registered';
    } catch (err) {
      error = true;
      const response = err.response;
      if (response?.status) {
        message = errorMessage(response.status);
      };
      if (!message) {
        message = 'error create user';
      }
    }

    return {
      error,
      message
    };
  };


  return (
    <CreateEntityForm
      title='create user'
      fields={fields}
      validation={validation}
      action={action}
      radioGroup={radioGroup}
    />
  );
};

export default CreateUser;
