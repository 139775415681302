import React from 'react';
import { useTranslation } from 'react-i18next';
import { listPortfolioUsers } from '../../../../services/portfolio';
import { createUserPortfolio, deleteUserPortfolio } from '../../../../services/user';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { getImpersonateLink } from '../../../../services/user';
import { useHistory } from 'react-router-dom';

export const UsersTable = ({ setMessage, data: portfolioData, setUsersCount }) => {
  const { t: translate } = useTranslation();

  const history = useHistory();
  const impersonateIconPath = `${process.env.REACT_APP_ICON_PATH}/Platform/Go-Gorila.svg`;

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove user')
      },
      addTooltip: translate('add user'),
      deleteTooltip: translate('remove user')
    }
  };

  const impersonateUser = async (rowData) => {
    const impersonateUrl = await getImpersonateLink(rowData.user.id);
    window.open(impersonateUrl.data);
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'User portfolio already exists':
      return setMessage(translate('User portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This userId doesnt exist'));
    default:
      return setMessage(translate(`NOT ${serviceName} user`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const userId = newData.user.id;
      const role = 'OWNER';
      const { id: portfolioId } = portfolioData;

      if (serviceName === 'inserted') {
        await service(userId, portfolioId, role);
      } else {
        await service(userId, portfolioId);
      }
      return setMessage(translate(`${serviceName} user`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createUserPortfolio, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', deleteUserPortfolio, oldData);
    }
  };

  const columns = [
    { title: 'UserId', field: 'user.id' },
    { title: translate('name'), field: 'user.name', editable: 'never' },
    { title: 'Email', field: 'user.email', editable: 'never' },
    { title: translate('role'), field: 'role', editable: 'never' }
  ];

  const actions = [
    {
      icon: () => (
        <img
          src={impersonateIconPath}
          alt='ImpersonateIcon'
          style={{ width: '20px', height: '20px' }}
        />
      ),
      tooltip: translate('impersonate user'),
      isFreeAction: false,
      onClick: (event, rowData) => {
        impersonateUser(rowData);
      }
    },
    {
      icon: VisibilityOutlinedIcon,
      tooltip: translate('user details'),
      onClick: ((e, rowData) => history.push(`/user/details/${rowData.user.id}`, { userData: rowData.user }))
    }
  ];

  const searchPortfolioUsers = async (offset, limit, search, count) => {
    const { data: response } = await listPortfolioUsers({ portfolioId: portfolioData.id, offset, limit, search, count, populate: 'user' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchPortfolioUsers}
        columns={columns}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setUsersCount}
        actions={actions}
      />
    </>
  );
};

export default UsersTable;
