import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUserPortfolio, deleteUserPortfolio, listUserPortfolios } from '../../../../services/user';
import { RemoteTable } from '../../../../shared/components';
import { PersonIcons } from '../../../../shared/MaterialIcons';

export const PortfoliosTable = ({ setMessage, data: userData, setPortfoliosCount }) => {
  const { t: translate } = useTranslation();

  const localization = {
    body: {
      editRow: {
        deleteText: translate('remove portfolio')
      },
      addTooltip: translate('add portfolio'),
      deleteTooltip: translate('remove portfolio')
    }
  };

  const errorMessage = (error, serviceName) => {
    const response = error.response.data.error || error;
    switch (response.message) {
    case 'User portfolio already exists':
      return setMessage(translate('User portfolio already exists'));
    case 'Invalid parameters provided':
      return setMessage(translate('This portfolioId doesnt exist'));
    default:
      return setMessage(translate(`NOT ${serviceName} portfolio`));
    }
  };

  const updateData = async (serviceName, service, newData) => {
    try {
      const portfolioId = newData.portfolio.id;
      const role = 'OWNER';
      const { id: userId } = userData;

      if (serviceName === 'inserted') {
        await service(userId, portfolioId, role);
      } else {
        await service(userId, portfolioId);
      }

      return setMessage(translate(`${serviceName} portfolio`));
    } catch (error) {
      errorMessage(error, serviceName);
    }
  };

  const editable = {
    onRowAdd: async (newData) => {
      return updateData('inserted', createUserPortfolio, newData);
    },
    onRowDelete: (oldData) => {
      return updateData('removed', deleteUserPortfolio, oldData);
    }
  };

  const columns = [
    { title: 'PortfolioId', field: 'portfolio.id' },
    { title: translate('portfolio name'), field: 'portfolio.name', editable: 'never' },
    { title: translate('role'), field: 'role', editable: 'never' }
  ];


  const searchUserPortfolios = async (offset, limit, search, count) => {
    const { data: response } = await listUserPortfolios({ userId: userData.id, offset, limit, search, count, populate: 'portfolio' });
    return response;
  };

  return (
    <>
      <RemoteTable
        setMessage={setMessage}
        dataService={searchUserPortfolios}
        columns={columns}
        editable={editable}
        icons={PersonIcons}
        localization={localization}
        setTotalCount={setPortfoliosCount}
      />
    </>
  );
};

export default PortfoliosTable;
