import axios from 'axios';
const FormData = require('form-data');

export const getUserList = async (search) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user`,
    params: {
      search
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const deleteUser = (param, callback) => {
  axios.delete(`${process.env.REACT_APP_URL_BACKOFFICE_API}user/${param.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  })
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(err.response || err);
    });
};

export const getUserDetails = (fundId, params = {}) => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}user/${fundId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    params: params
  });
};

export const getOrganizationUserDetails = (userId) => {
  return axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user`, {
    params: {
      user: userId,
      populate: 'organization',
      limit: 1000
    },
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  });
};

export const updateUser = (infos) => {
  const { id, name, email, status } = infos;
  return axios.patch(`${process.env.REACT_APP_URL_BACKOFFICE_API}user/${id}`, { name, email, status }, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  });
};

export const deletePortfolio = (param, callback) => {
  axios.delete(`${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/${param.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  })
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(err.response || err);
    });
};

export const rerunPortfolio = (param, resolve, reject) => {
  axios.post(`${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/rerun`, { userId: param.id }, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  })
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const transferAccount = (params) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/clone`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    data: params
  });
};

export const downloadTabularReport = ({ fundId, minDate, maxDate, portfolioRelations }) => (
  fetch(`${process.env.REACT_APP_URL_BACKOFFICE_API}portfolio/tabular/report/${fundId}?minDate=${minDate}&maxDate=${maxDate}`, {
    headers: new Headers({
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'portfolio-relations': portfolioRelations
    }),
    method: 'GET'
  })
);

export const getImpersonateLink = (fundId) => (
  axios.get(`${process.env.REACT_APP_URL_BACKOFFICE_API}user/${fundId}/impersonate`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  })
);


export const createUser = (body) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    data: body
  });
};

export const uploadAdvisorLogo = (file, fundId) => {
  const form = new FormData();
  form.append('file', file);

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user/logo/create/${fundId}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    data: form
  });
};

export const removeAdvisorLogo = (fundId) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user/logo/remove/${fundId}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
  });
};

export const checkSupportOrg = async (fundId) => {
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}support-organization/user/${fundId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  return data;
};

export const addToSupportOrg = async (fundId) => {
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}support-organization/user/${fundId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  return data;
};

export const removeFromSupportOrg = async (fundId) => {
  const { data } = await axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}support-organization/user/${fundId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  return data;
};

export const getOrganizationUserPortfolios = async (params) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    params: {
      params
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listUserPortfolios = async (params) => {
  const { userId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user-portfolio`,
    params: {
      user: userId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const createUserPortfolio = async (user, portfolio, role) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user-portfolio`,
    data: {
      user,
      portfolio,
      role
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const deleteUserPortfolio = async (user, portfolio) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}user-portfolio`,
    data: {
      user,
      portfolio
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listUserOrganizations = async (params) => {
  const { userId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user`,
    params: {
      user: userId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

export const listUserOrganizationPortfolios = async (params) => {
  const { userId, offset, limit, search, count, populate } = params;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_URL_BACKOFFICE_API}organization-user-portfolio`,
    params: {
      user: userId,
      offset,
      limit,
      search,
      count,
      populate
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};
