import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getUserDetails } from '../../../services/user';
import { useParams, useHistory } from 'react-router-dom';
import DetailsPage from '../../../shared/components/DetailsPage/DetailsPage';
import ImportLogo from './Tabs/ImportLogo';
import UserIntegrations from './Tabs/UserIntegrations';
import PortfoliosTable from './Tabs/PortfoliosTable';
import OrgPortfoliosTable from './Tabs/OrgPortfoliosTable';
import OrgsTable from './Tabs/OrgsTable';

const UserDetails = () => {
  const { t: translate } = useTranslation();
  const { FundID } = useParams();
  const history = useHistory();

  const [userData, setUserData] = useState(history?.location?.state?.userData || {});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [userLogo, setUserLogo] = useState('');

  const [portfoliosCount, setPortfoliosCount] = useState(null);
  const [orgsCount, setOrgsCount] = useState(null);
  const [orgPortfoliosCount, setOrgPortfoliosCount] = useState(null);
  const [integrationsCount, setIntegrationsCount] = useState(null);

  useEffect( () => {
    setUserLogo('');

    const prepareLogo = () => {
      const random = Math.floor(Math.random() * 1000000) + 1;
      const logoUrlS3 = `${process.env.REACT_APP_AMAZON_LEGACY}/logo/${process.env.REACT_APP_STAGE}/${FundID}.png?random=${random}`;
      setUserLogo(logoUrlS3);
    };

    const getDetails = async () => {
      try {
        setLoading(true);
        const response = await getUserDetails(FundID);
        setUserData(response.data);

        prepareLogo();
      } catch (err) {
        setMessage(translate('it was not possible to search user details'));
      }
      setLoading(false);
    };

    if (!Object.keys(userData).length) {
      getDetails();
    } else {
      prepareLogo();
    }
  }, [FundID, userData, translate]);


  const header = {
    title: userData?.name,
    fields: [
      {
        name: 'userId',
        data: userData?.id
      },
      {
        name: 'email',
        data: userData?.email
      },
      {
        name: 'status',
        data: translate(userData?.status)
      }
    ],
    imageUrl: userLogo
  };

  const tabs = [
    {
      name: `${translate('portfolios')}` + (portfoliosCount !== null ? ` (${portfoliosCount})` : ''),
      content: {
        component: PortfoliosTable,
        props: {
          setPortfoliosCount
        }
      }
    },
    {
      name: `${translate('organizations')}` + (orgsCount !== null ? ` (${orgsCount})` : ''),
      content: {
        component: OrgsTable,
        props: {
          setOrgsCount
        }
      }
    },
    {
      name: `${translate('organizations portfolios')}` + (orgPortfoliosCount !== null ? ` (${orgPortfoliosCount})` : ''),
      content: {
        component: OrgPortfoliosTable,
        props: {
          setOrgPortfoliosCount
        }
      }
    },
    {
      name: `${translate('integrations')}` + (integrationsCount !== null ? ` (${integrationsCount})` : ''),
      content: {
        component: UserIntegrations,
        props: {
          setIntegrationsCount
        }
      }
    },
    {
      name: translate('logo'),
      content: {
        component: ImportLogo,
        props: {
          setLogo: setUserLogo
        }
      }
    }
  ];

  return (
    <DetailsPage
      tabs={tabs}
      message={message}
      loading={loading}
      header={header}
      data={userData}
      setMessage={setMessage}
    />
  );
};

export default UserDetails;
